// 监测机构
const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                prop: 'JCJGMC',
                label: '机构名称',
                minWidth: 155
            }, {
                prop: 'FZR',
                label: '负责人'
            }, {
                prop: 'BBH',
                label: '版本号'
            }, {
                prop: 'LXRXM',
                label: '联系人姓名',
                minWidth: 105
            }],
        },
        // 操作列
        columnsBtn: {
            buttonData: []
        },
    },
    // snSearch: {
    //     selectData: [{
    //         label: '保护管理机构级别',
    //         clearable: true,
    //         placeholder: "请选择保护管理机构级别",
    //         list: [{
    //             NAME: '正厅（局）级'
    //         }, {
    //             NAME: '副厅（局）级'
    //         }, {
    //             NAME: '正处（县）级'
    //         }, {
    //             NAME: '副处（县）级'
    //         }, {
    //             NAME: '正科（乡）级'
    //         }, {
    //             NAME: '副科（乡）级'
    //         }, {
    //             NAME: '股级'
    //         }, {
    //             NAME: '副股级'
    //         }, {
    //             NAME: '其他'
    //         }],
    //         optionLabel: 'NAME',
    //         optionValue: 'NAME',
    //         value: 'JGJB',
    //         operateType: 'search',
    //         isShow: true
    //     }]
    // }
};
export default selfData;